export const MAX_FILE_SIZE_BYTES = 500000;

export const ASPECT_RATIO_4to3 = 1 + (1 / 3);

export const ASPECT_RATIO_3to2 = 1 + (1 / 2);

export const ASPECT_RATIO_2to3 = 2 / 3;
export const PRESET_WEB_SIZES = Object.freeze([{
    WEB_WIDTH: 2100,
    WEB_HEIGHT: 1400,
    WEB_RATIO: 1 + (1 / 2)
}, {
    WEB_WIDTH: 1920,
    WEB_HEIGHT: 1440,
    WEB_RATIO: 1 + (1 / 3)
}, {
    WEB_WIDTH: 1800,
    WEB_HEIGHT: 1200,
    WEB_RATIO: 1 + (1 / 2)
}, {
    WEB_WIDTH: 1400,
    WEB_HEIGHT: 2100,
    WEB_RATIO: 2 / 3
}]);

export const ALLOWED_WATERMARK_FILE_TYPES = Object.freeze([
    'image/png',
]);

export const DEFAULT_WEB_SIZE = Object.freeze({
    width: 2100,
    height: 1400,
    ratio: 1 + (1 / 2)
});